import { memo, useMemo } from 'react';
import { Parallax, ParallaxProvider, type EffectString } from 'react-scroll-parallax';

import { customerSupports } from '@data/teammates.ts';
import { TeammateInfoButton } from '@components/common';
import TeammateInfoButtonIcon from '@assets/teammate-info-button-icon.svg?react';
import styles from './CustomerSupportsInfo.module.scss';

const colors = ['#29ABDC45', '#FDD03A45', '#39B54A45', '#ED217745'];
const topLightColors = ['#FDD03A14', '#39B54A14', '#ED217714', '#29ABDC14'];
const bottomLightColors = ['#ED217714', '#29ABDC14', '#FDD03A14', '#39B54A14'];
const offsets = [10, 2, 10, 2];

const offsetA = -25;
const offsetB = 25;
const unit = '%';

const CustomerSupportsInfo = () => {
    return (
        <div className={styles.customerSupportsInfo}>
            {customerSupports.map((item, i) => (
                <CustomerSupportsInfoItem key={item.id} index={i} {...item} />
            ))}
        </div>
    );
};

const CustomerSupportsInfoItem = memo(
    ({
        index: i,
        name,
        photo,
        avatar,
        avatarFallback,
        photoFallback,
        ...item
    }: (typeof customerSupports)[0] & { index: number }) => {
        const { translateY, topItemStyle, middleItemStyle, bottomItemStyle } = useMemo(
            () => ({
                translateY: [
                    `${offsetA * (i % 2 === 0 ? -1 : 1) + offsets[i]}${unit}`,
                    `${offsetB * (i % 2 === 0 ? -1 : 1) + offsets[i]}${unit}`,
                ] as EffectString,
                topItemStyle: {
                    backgroundColor: topLightColors[i],
                },
                middleItemStyle: { backgroundColor: colors[i] },
                bottomItemStyle: { backgroundColor: bottomLightColors[i] },
            }),
            [i],
        );

        return (
            <Parallax className={styles.customerSupportsInfoItem} translateY={translateY}>
                <div className={styles.customerSupportsInfoItemIllu} style={topItemStyle}></div>

                <div className={styles.customerSupportsInfoItemIllu} style={middleItemStyle}>
                    <TeammateInfoButton
                        {...item}
                        name={name}
                        avatar={avatar.src}
                        avatarFallback={avatarFallback.src}
                        className={styles.customerSupportsNameTag}>
                        <span className={styles.customerSupportsInfoIcon}>
                            <TeammateInfoButtonIcon />
                        </span>
                        {name}
                    </TeammateInfoButton>
                    <div className={styles.customerSupportsInfoItemImageContainer}>
                        <picture>
                            <source srcSet={photo.src} type="image/webp" />
                            <img src={photoFallback.src} alt={name} />
                        </picture>
                    </div>
                </div>

                <div className={styles.customerSupportsInfoItemIllu} style={bottomItemStyle}></div>
            </Parallax>
        );
    },
);

const CustomerSupportsInfoWithWrapper = () => {
    return (
        <ParallaxProvider>
            <CustomerSupportsInfo />
        </ParallaxProvider>
    );
};

export default memo(CustomerSupportsInfoWithWrapper);
