import KristinaPhoto from '@assets/service-kristina.webp';
import KristinaPhotoFallback from '@assets/service-kristina.png';
import IrinaPhoto from '@assets/service-irina.webp';
import IrinaPhotoFallback from '@assets/service-irina.png';
import MichaelPhoto from '@assets/service-michael.webp';
import MichaelPhotoFallback from '@assets/service-michael.png';
import DianaPhoto from '@assets/service-diana.webp';
import DianaPhotoFallback from '@assets/service-diana.png';
import KatharinaPhoto from '@assets/service-katharina.webp';
import KatharinaPhotoFallback from '@assets/service-katharina.png';
import KristinaAvatar from '@assets/avatars/kristina.webp';
import KristinaAvatarFallback from '@assets/avatars/kristina.png';
import IrinaAvatar from '@assets/avatars/irina.webp';
import IrinaAvatarFallback from '@assets/avatars/irina.png';
import MichaelAvatar from '@assets/avatars/michael.webp';
import MichaelAvatarFallback from '@assets/avatars/michael.png';
import DianaAvatar from '@assets/avatars/diana.webp';
import DianaAvatarFallback from '@assets/avatars/diana.png';
import Irina2Avatar from '@assets/avatars/irinab.webp';
import Irina2AvatarFallback from '@assets/avatars/irinab.png';
import KatharinaAvatar from '@assets/avatars/katharina.webp';
import KatharinaAvatarFallback from '@assets/avatars/katharina.png';
import KatjaAvatar from '@assets/avatars/katja.webp';
import KatjaAvatarFallback from '@assets/avatars/katja.png';
import KorbinianAvatar from '@assets/avatars/korbinian.webp';
import KorbinianAvatarFallback from '@assets/avatars/korbinian.png';
import MalteAvatar from '@assets/avatars/malte.webp';
import MalteAvatarFallback from '@assets/avatars/malte.png';
import ShashankAvatar from '@assets/avatars/shashank.webp';
import ShashankAvatarFallback from '@assets/avatars/shashank.png';
import TobiasAvatar from '@assets/avatars/tobias.webp';
import TobiasAvatarFallback from '@assets/avatars/tobias.png';
import ViktoriaAvatar from '@assets/avatars/viktoria.webp';
import ViktoriaAvatarFallback from '@assets/avatars/viktoria.png';

export const teammatesMap = {
    // kristina: {
    //     id: 'kristina',
    //     name: 'Kristina',
    //     about: 'Kristina ist die Top-Expertin in Schnelltestbutler. Die Automatisierung der Unternehmensprozesse wirkt in ihren Händen wie Zauberei. Kristina ist sachkundig, verständnisvoll und geduldig mit Kunden wie mit ihren 3 Kindern.',
    //     photo: KristinaPhoto,
    //     photoFallback: KristinaPhotoFallback,
    //     avatar: KristinaAvatar,
    //     avatarFallback: KristinaAvatarFallback,
    //     position: 'Account Managerin',
    // },
    irina: {
        id: 'irina',
        name: 'Irina',
        about: 'Hi, mein Name ist Irina und ich stehe dir mit Rat & Tat zur Seite wenn du dich für Butlerapp interessierst. Kurz gesagt, ich bin verantwortlich für die Erstberatung von Neukunden. Über meine Arbeit hinaus bin ich ein aktives Mitglied in der katholischen Kirchengemeinde und bin verliebt in die Berge und Natur.',
        photo: IrinaPhoto,
        photoFallback: IrinaPhotoFallback,
        avatar: IrinaAvatar,
        avatarFallback: IrinaAvatarFallback,
        position: 'Teamleiterin Erstberatung',
    },
    michael: {
        id: 'michael',
        name: 'Michael',
        about: 'Hi, mein Name ist Michael und ich leite das Kundenbetreuungsteam von Butlerapp. Das heisst für mich, das mein Team alles für dich aus Butlerapp rausholt was möglich ist. Über meine Arbeit hinaus habe ich viel zutun mit meinen 2 Kindern, Schwimme und trainiere Thai-Boxing.',
        photo: MichaelPhoto,
        photoFallback: MichaelPhotoFallback,
        avatar: MichaelAvatar,
        avatarFallback: MichaelAvatarFallback,
        position: 'Teamleiter Kundenbetreuung',
    },
    diana: {
        id: 'diana',
        name: 'Diana',
        about: 'Hi, ich bin Diana, Senior Kundenbetreuerin von Butlerapp. Mein Ziel ist es, das Butlerapp für dich maximalen nutzen bietet und natürlich, das du bei Fragen nicht lange warten brauchst. Neben der Arbeit mache ich gerne Sport, besonders Air-Stretching.',
        photo: DianaPhoto,
        photoFallback: DianaPhotoFallback,
        avatar: DianaAvatar,
        avatarFallback: DianaAvatarFallback,
        position: 'Senior Kundenbetreuerin',
    },
    korbinian: {
        id: 'korbinian',
        name: 'Korbinian',
        about: 'Hi, Corbinian hier. Meine Verantwortung ist Online-Marketing und ich bin der Grund, warum du hier auf dieser Website bist. In meiner Freizeit spiele ich gerne elektronische Musik.',
        photo: KorbinianAvatar,
        photoFallback: KorbinianAvatarFallback,
        avatar: KorbinianAvatar,
        avatarFallback: KorbinianAvatarFallback,
        position: 'Online-Marketing ',
    },
    katharina: {
        id: 'katharina',
        name: 'Katharina',
        about: 'Hallo, mein Name ist Katharina und ich arbeite Teilzeit in der Kundenbetreuung, da ich aktuell im Mutterschaftsurlaub bin. Neben meinen 2 Kindern und der Arbeit liebe ich meinen Gemüsegarten.',
        photo: KatharinaPhoto,
        photoFallback: KatharinaPhotoFallback,
        avatar: KatharinaAvatar,
        avatarFallback: KatharinaAvatarFallback,
        position: 'Kundenbetreuerin',
    },
    // malte: {
    //     id: 'malte',
    //     name: 'Malte',
    //     about: '',
    //     photo: MalteAvatar,
    //     photoFallback: MalteAvatarFallback,
    //     avatar: MalteAvatar,
    //     avatarFallback: MalteAvatarFallback,
    //     position: 'Gründer und Head of Sales',
    // },
    irinaB: {
        id: 'irinaB',
        name: 'Irina',
        about: 'Hallo, mein Name ist Irina, ich arbeite Teilzeit bei Butlerapp und kümmere mich um alle administrativen Themen, die in der Geschäftsführung liegen bleiben. Du hast also wahrscheinlich nicht mit mir zutun aber meine Verantwortung ist, das auch das läuft was du nicht siehst. In meiner Freizeit mache ich gerne lange Spaziergänge.',
        photo: Irina2Avatar,
        photoFallback: Irina2AvatarFallback,
        avatar: Irina2Avatar,
        avatarFallback: Irina2AvatarFallback,
        position: 'Assistentin der Geschäftsführung',
    },
    katja: {
        id: 'katja',
        name: 'Katja',
        about: 'Hallo, mein Name ist Katja und ich kümmere mich um die Buchhaltung und das Rechnungswesen. Und wenn du mal nicht zahlst, dann schicke ich dir erst eine nette Zahlungserinnerung und dann eine nicht so nette ;-). Neben der Arbeit habe ich zwei kleine Kinder.',
        photo: KatjaAvatar,
        photoFallback: KatjaAvatarFallback,
        avatar: KatjaAvatar,
        avatarFallback: KatjaAvatarFallback,
        position: 'Buchhalterin',
    },
    tobias: {
        id: 'tobias',
        name: 'Tobias',
        about: 'Hallo, mein Name ist Tobias. Ich habe diese Firma vor 6 Jahre gegründet. Meine Philosophie ist, das Unternehmen erfolgreich werden, wenn diese maximalen Kundennutzen bieten. Und ich hoffe, das spürst du. Arbeit ist für mich meistens Freizeit und ich arbeite meistens mehr als 40 Stunden. Deswegen bleibt offt wenig "Freizeit" über, aber wenn doch, kannst du mich mit einem guten Buch, an der Kletterwand oder auf 5000 Meter höhe finden.',
        photo: TobiasAvatar,
        photoFallback: TobiasAvatarFallback,
        avatar: TobiasAvatar,
        avatarFallback: TobiasAvatarFallback,
        position: 'Gründer und Geschäftsführer',
    },
    // viktoria: {
    //     id: 'viktoria',
    //     name: 'Viktoria',
    //     about: '',
    //     photo: ViktoriaAvatar,
    //     photoFallback: ViktoriaAvatarFallback,
    //     avatar: ViktoriaAvatar,
    //     avatarFallback: ViktoriaAvatarFallback,
    //     position: 'Senior Sales Assistentin',
    // },
    shashank: {
        id: 'shashank',
        name: 'Shashank',
        about: 'Hi, mein Name ist Shashank. Ich leite die Frontend-Entwicklung. Die Butlerapp Software habe ich zu einem grossen Teil programmiert. In meiner Freizeit bin ich gerne unterwegs: Ob in der Natur oder auf einer Stadtbesichtigung, Hauptsache draussen und unterwegs.',
        photo: ShashankAvatar,
        photoFallback: ShashankAvatarFallback,
        avatar: ShashankAvatar,
        avatarFallback: ShashankAvatarFallback,
        position: 'Frontend Entwickler',
    },
};

export const customerSupports = [
    teammatesMap.irina,
    teammatesMap.michael,
    teammatesMap.diana,
    teammatesMap.katharina,
];

export const teammates = Object.values(teammatesMap);
